<template>
  <div>
    <div class="tab-content">
      <div class="form-containerx">
        <label>Jenjang Soal</label>
        <br />
        <b-form inline>
          <b-form-select
            v-model="inputData.tryout_level_id"
            :options="levelOption"
            disabled
            class="mrr-22 mrb-5"
          ></b-form-select>

          <b-form-select
            v-model="inputData.tryout_subject_id"
            :options="subjectOption"
            disabled
            class="mrb-5"
            v-show="subjectStatus"
          ></b-form-select>
        </b-form>
        <br />
        <br />

<!--         <label>Mode Generate Soal</label><br />
        <b-form inline>
          <b-form-select
            :options="generateSoal"
            disabled
            class="mrr-22 mrb-5"
            v-model="inputData.mode"
          ></b-form-select>
        </b-form>
        <br />
        <br /> -->

        <!-- <label>Tentukan Jumlah Soal</label><br />
        <b-form inline>
          <b-form-input
          placeholder=""
          v-model="inputData.jumlahSoal"
        ></b-form-input> 
        &nbsp;&nbsp;&nbsp;<span style="font-size:14pt">Soal</span>
        </b-form><br /><br /> -->

        <label>Judul Paket Soal</label><br />
        <b-form-input
          placeholder="Title..."
          v-model="inputData.name"
        ></b-form-input>
        <br />
        <br />
        <label>Durasi Soal (menit)</label><br />
        <b-form-input
          placeholder="Ex: 30"
          type="number"
          v-model="inputData.time_limit"
        ></b-form-input>
        <br />
        <br />
        <div class="txt-right">
          <b-button
            size="md"
            variant="primary"
            class="e-button bg-color2 e-button--not-rounded"
            @click="updateSoal"
            v-show="!showLoading"
          >
            SIMPAN
          </b-button>
          <b-button
            size="md"
            variant="primary"
            class="e-button bg-color2 e-button--not-rounded"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </b-button>
        </div>
        <br />
        <br />
        <br />
        <label>Ubah Daftar Soal</label>
        <br />
        <br />

        <div class="space-between align-center">
          <div class="align-center flex-1">
          </div>

          <button
            type="button"
            class="btn mulai btn-secondary font-bold"
            @click="pilihSoalBaru"
          >
            <fa-icon icon="edit" class="my-icon" />&nbsp; CARI & TAMBAH SOAL
          </button>
        </div>
        <br />
        <br />

        <!-- Main table element -->
        <table class="table table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col" v-for="field in fields" :key="field.label">
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <draggable v-model="items" tag="tbody" @change="log">
            <tr
              v-for="(item,i) in items"
              :key="item.id"
              style="cursor: all-scroll;"
            >
              <td>{{ i+1 }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.level }}</td>
              <td>{{ inputData.tryout_level }}</td>
              <td>{{ item.tryout_subject }}</td>
              <td>
                <b-button
                  size="sm"
                  class="mr-3 action-button"
                  variant="outline-secondary"
                  @click="viewSoal(item.id)"
                >
                  <fa-icon icon="search" class="my-icon" />&nbsp;View Soal
                </b-button>
                <b-button
                  size="sm"
                  class="mr-3 action-button"
                  variant="outline-danger"
                  @click="removeQuestion(item.id, item.level)"
                >
                  <fa-icon icon="trash-alt" class="my-icon" />&nbsp;Remove
                </b-button>
              </td>
            </tr>
          </draggable>
        </table>
        <br />
        <b-button
          variant="info"
          size="sm"
          v-if="saveOrderButton"
          @click="saveOrder()"
        >
          Simpan Urutan
        </b-button>
        <br />
        <br />
      </div>
      <!-- Modal Bank Soal -->
    <b-modal
      v-model="pilihModal"
      id="pilihModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="pilihModal = false"></modal-cross>
        <div class="p-4">
          <h3 class="txt-center color2">Pilih Soal</h3>
          <br />
          <!-- <label>Cari Judul Soal</label>
          <br />
          <b-form-input placeholder="Title..." v-model="uploadData.title"></b-form-input> -->
          <!-- Main table element -->
          <div class="space-between align-center">
            <div class="align-center flex-1">
              <div class="filter-icon">
                <img src="/img/filter.svg" alt />
              </div>

              <div class="search-wrappper">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    placeholder="Search here..."
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
          <br />
          <br />
          <b-table
            show-empty
            stacked="md"
            :busy="isBusy2"
            :items="items2"
            :fields="fields2"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            selectable
            :select-mode="selectMode"
            selected-variant="success"
            @row-selected="onRowSelected"
            responsive="sm"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="mr-3 action-button"
                variant="outline-secondary"
                @click="viewSoal(row.item.id)"
              >
                <fa-icon icon="search" class="my-icon" />&nbsp;View Soal
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col md="6" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
          <div class="justify-end">
            <my-button
              type="blue"
              @click="addQuestion"
            >
              SUBMIT SOAL
            </my-button>
          </div>
        </div>
      </b-modal>
      <!-- Modal Lihat Detail Soal -->
    <b-modal
      v-model="lihatSoal"
      id="lihatSoal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="lihatSoal = false"></modal-cross>
      <div class="p-4">
          <h3 class="txt-center color2">Detail Soal</h3>
          <br />
          <b>Pertanyaan</b>
          <div
            v-katex="{expression: question ? question : ' ', options: { macros: {' ': '\\space '} }}"
          ></div>
          <div class="upload-image" v-show="gallery">
            <div
              class="upload-image__item"
            >
              <div class="upload-image__top">
                <div class="image-container">
                  <img class="image-container-img" :src="gallery" alt="img" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <!-- <b>
            Pilihan Jawaban
          </b> -->
          &nbsp;<b>A</b>
          <div class="view-latex-soal">
            <div
              v-katex="{expression: option1 ? option1 : ' ', options: { macros: {' ': '\\space '} }}"
            ></div>
          </div>
          <br />
          &nbsp;<b>B</b>
          <div class="view-latex-soal">
            <div
              v-katex="{expression: option2 ? option2 : ' ', options: { macros: {' ': '\\space '} }}"
            ></div>
          </div>
          <br />
          &nbsp;<b>C</b>
          <div class="view-latex-soal">
            <div
              v-katex="{expression: option3 ? option3 : ' ', options: { macros: {' ': '\\space '} }}"
            ></div>
          </div>
          <br />
          &nbsp;<b>D</b>
          <div class="view-latex-soal">
            <div
              v-katex="{expression: option4 ? option4 : ' ', options: { macros: {' ': '\\space '} }}"
            ></div>
          </div>
          <br />
          &nbsp;<b>E</b>
          <div class="view-latex-soal">
            <div
              v-katex="{expression: option5 ? option5 : ' ', options: { macros: {' ': '\\space '} }}"
            ></div>
          </div>
          <br />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  data() {
    return {
      // editorOptions: {
      //   modules: {
      //     toolbar: [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      //     [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      //     [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      //     [{ 'direction': 'rtl' }],                         // text direction
      //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      //     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      //     [{ 'font': [] }],
      //     [{ 'align': [] }]],
      //   },
      //   theme: "snow"
      // },
      saveOrderButton: false,
      subjectStatus: true,
      selectedPackage: 'one',
      packageOption: [
        { text: 'Satu Mata Pelajaran', value: 'one'},
        { text: 'Beberapa Mata Pelajaran', value: 'mix' }
      ],
      isBusy: false,
      isBusy2: false,
      question: "",
      gallery: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      option5: "",
      lihatSoal: false,
      selectMode: "multi",
      selected: [],
      pilihModal: false,
      settingsOptions: [
        { text: "Lock", value: true },
        { text: "Unlock", value: false }
      ],
      clickedItem: {},
      fields2: [
        { key: "no", label: "No" },
        {
          key: "title",
          label: "Judul Soal",
          sortable: true,
          sortDirection: "desc"
        },
        
        {
          key: "level",
          label: "Level",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: true,
          class: "text-center"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Action" }
      ],
      items2: [],
      items: [],

      fields: [
        { key: "no", label: "No" },
        {
          key: "title",
          label: "Judul Paket",
          sortable: false
        },
        {
          key: "level",
          label: "Level",
          sortable: false
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: false,
          class: "text-center"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: false,
          class: "text-center"
        },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      generateSoalMode: false,
      // generateSoalStatus: 'random',
      classOption: [],
      levelOption: [],
      generateSoal: [
        // { text: '', value: null },
        { text: "Random", value: "random" },
        { text: "Manual", value: "manual" }
      ],
      subjectOption: [],

      inputData: {
        tryout_level_id: null,
        tryout_level: "",
        tryout_subject_id: null,
        name: "",
        mode: "",
        id: 0,
        time_limit: null
      },
      alert: {
        variant: "success",
        message: "Success",
        response: "",
        dismissCountDown: 0
      },
      showLoading: false
    };
  },
  methods: {
    saveOrder() {
      this.$bvModal
        .msgBoxConfirm("Save new question order?")
        .then(value => {
          if (value) {
            var orderAllIds = [];
            for (var i = 0; i < this.items.length; i++) {
              orderAllIds.push(this.items[i].id);
            }

            this.updateTryoutQuestionOrder({
              id: this.$route.params.id,
              order: orderAllIds.join(',')
            })
            .then((response) => {
              this.showMessageBox('Berhasil mengubah urutan soal', 'Success', 'success');
            })
            .catch((error) => {
              this.showMessageBox(error, 'Gagal mengubah urutan soal', 'success');
            });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    log() {
      this.saveOrderButton = true;
    },
    viewSoal(id) {
      this.lihatSoal = true;
      this.getTryoutQuestionDetail(id)
        .then(response => {
          var res = response[0];
          this.question = res.question;
          this.option1 = res.options_json[0].option;
          this.option2 = res.options_json[1].option;
          this.option3 = res.options_json[2].option;
          this.option4 = res.options_json[3].option;
          this.option5 = res.options_json[4].option;
          if (res.gallery) {
            this.gallery = res.gallery;
          } else {
            this.gallery = "";
          }
        })
        .catch(error => {
          this.lihatSoal = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    hideModal() {
      this.lihatSoal = false;
    },
    ...mapActions({
      getTryoutPackageDetail: "getTryoutPackageDetail",
      updateTryoutPackage: "updateTryoutPackage",
      getTryoutQuestionList: "getTryoutQuestionList",
      getTryoutQuestionDetail: "getTryoutQuestionDetail",
      setTryoutPackageQuestion: "setTryoutPackageQuestion",
      removeTryoutPackageQuestion: "removeTryoutPackageQuestion",
      updateTryoutQuestionOrder: "updateTryoutQuestionOrder"
    }),
    tryoutQuestionList(tryout_package=0) {
      var tryout_level_id = this.inputData.tryout_level_id;
      var tryout_subject_id = this.inputData.tryout_subject_id;
      var payload = {
        params: {
          tryout_level: tryout_level_id,
          tryout_subject: tryout_subject_id
        }
      }
      if(tryout_package) {
        payload.params.package = tryout_package;
        this.isBusy = true;
      } else {
        this.isBusy2 = true;
      }
      this.getTryoutQuestionList(payload)
        .then(response => {
          var res = response;
          if(tryout_package) {
            this.items = [];
            for (var i = 0; i < res.length; i++) {
              var dataItem = {
                no: i + 1,
                id: res[i].id,
                title: res[i].title,
                tryout_level: res[i].tryout_level,
                tryout_level_id: res[i].tryout_level_id,
                tryout_subject: res[i].tryout_subject,
                tryout_subject_id: res[i].tryout_subject_id,
                level: res[i].level
              };
              this.items.push(dataItem);
            }
            this.isBusy = false;
          } else {
            this.items2 = [];
            for (var i = 0; i < res.length; i++) {
              var dataItem = {
                no: i + 1,
                id: res[i].id,
                title: res[i].title,
                tryout_level: res[i].tryout_level,
                tryout_level_id: res[i].tryout_level_id,
                tryout_subject: res[i].tryout_subject,
                tryout_subject_id: res[i].tryout_subject_id,
                level: res[i].level
              };
              this.items2.push(dataItem);
            }
            this.totalRows = res.length;
            this.isBusy2 = false;
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    tryoutPackageDetail(id) {
      this.getTryoutPackageDetail(id)
        .then(response => {
          var res = response;
          if (res.tryout_subject_id) {
            var dataSubject = {
              text: res.tryout_subject,
              value: res.tryout_subject_id
            };
            this.selectedPackage = 'one';
            this.subjectOption = [];
            this.subjectOption.push(dataSubject);
          } else {
            this.subjectStatus = false;
            this.selectedPackage = 'mix';
          }
          var dataLevel = {
            text: res.tryout_level,
            value: res.tryout_level_id
          };
          
          this.levelOption = [];
          
          this.levelOption.push(dataLevel);
          this.inputData.tryout_level_id = res.tryout_level_id;
          this.inputData.tryout_subject_id = res.tryout_subject_id;
          this.inputData.tryout_level = res.tryout_level;
          this.inputData.name = res.name;
          this.inputData.mode = res.mode;
          this.inputData.id = res.id;
          this.inputData.time_limit = res.time_limit;
          this.items = res.questions;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    updateSoal() {
      this.showLoading = true;
      var payload = {
        name: this.inputData.name,
        id: this.inputData.id,
        time_limit: this.inputData.time_limit
      };
      if (confirm("Save changes?")) {
        this.updateTryoutPackage(payload)
          .then(response => {
            var res = response;
            this.tryoutPackageDetail(this.$route.params.id);
            this.showLoading = false;
            this.showMessageBox("Berhasil update soal", "Failed", "success");
          })
          .catch(error => {
            this.showLoading = false;
            this.showMessageBox(error, "Failed", "success");
          });
      }
    },
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
    showAlert(seconds) {
      this.alert.dismissCountDown = seconds;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    pilihSoalBaru() {
      this.pilihModal = true;
      this.tryoutQuestionList();
    },
    generateSoalFunction() {
      this.generateSoalMode = !this.generateSoalMode;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoToBuat() {
      this.$router.push("/paket-soal/buat");
    },

    gotoEdit(no) {
      this.$router.push("/paket-soal/edit/" + no);
    },
    addQuestion() {
      var questions = [];
      for (var i = 0; i < this.selected.length; i++) {
        var status = this.items.length > 0 ? this.items.some(j => j.id === this.selected[i].id) : 0;
        if(!status) {
          questions.push(this.selected[i]);
        }
      }
      if(questions.length > 0) {
        this.setTryoutPackageQuestion({tryout_package_id: this.$route.params.id, questions: questions})
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.pilihModal = false;
          this.tryoutPackageDetail(this.$route.params.id);
          // this.saveOrder();
        })
        .catch(error => {
          this.pilihModal = false;
          this.showMessageBox(error, "Failed", "success");
        });
      } else {
        this.showMessageBox("Soal sudah terdaftar", "Failed", "success");
      }
    },
    removeQuestion(id, level) {
      if (confirm("Do you really want to delete?")) {
        var payload = {
          tryout_package_id: this.$route.params.id,
          tryout_question_id: id,
          level: level
        };
        this.removeTryoutPackageQuestion(payload)
          .then(response => {
            // eslint-disable-next-line
            var res = response;
            this.pilihModal = false;
            this.tryoutPackageDetail(this.$route.params.id);
          })
          .catch(error => {
            this.pilihModal = false;
            this.showMessageBox(error, "Failed", "success");
          });
      }
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
          this.temp = value;
        })
        .catch(err => {
          this.temp = err;
        });
    }
  },
  computed: {
    // generateSoalStatus(){
    //     if( this.){

    //     },
    //     if(){

    //     }
    // }
    jumlahSoalEasy() {
      return this.inputData.jumlahSoal;
    },
    jumlahSoalMedium() {
      return this.inputData.jumlahSoal;
    },
    jumlahSoalHard() {
      return this.inputData.jumlahSoal;
    },
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.tryoutPackageDetail(this.$route.params.id);
    // this.tryoutQuestionList(this.$route.params.id);
  }
};
</script>

<style>
.form-containerx {
  max-width: 1000px;
}
.view-latex-soal {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 10pt;
  min-height: 60px;
}
.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}
</style>
